<template>
    <div class="wrapper_main footer-fixed">
        <main class="content">
            <div class="content__header none-border">
                <div class="content__header-left">
                    <div class="headline">
                        <h1 class="headline__title">{{ $translate('add-field') }}</h1>
                        <p class="headline__text">{{ $translate('set-up-field-on-map-and-write-down-basic-info') }}</p>
                    </div>
                </div>
                <div class="content__header-right">
                    <div class="buttons-group margin-btn flex-auto">
                        <button class="btn btn-w-shadow btn-standart-2" @click="changeAreaType(0)" :class="{active: areaType==0}">
                            <inline-svg :src="require(`@/assets/img/definition-icon.svg`)"/>
                            <span class="blue-sky">{{ $translate('borders-definition') }}</span>
                        </button>
                        <button class="btn btn-w-shadow btn-standart-2" @click="changeAreaType(1)" :class="{active: areaType==1}">
                            <inline-svg :src="require(`@/assets/img/privot-aria-icon.svg`)"/>
                            <span>{{ $translate('pivot-area') }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div class="tabs-wrapper">
                <ul class="tabs">
                    <li class="tabs__item"> 
                        <a data-tab="map" class="nav-tab" @click="switchTab('map')" :class="isActiveTab('map')"> {{ $translate('map') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="basic-info" class="nav-tab" @click="switchTab('basic-info')" :class="isActiveTab('basic-info')"> {{ $translate('basic-info') }} </a>
                    </li>
                    <li class="tabs__item">
                        <a data-tab="assign-devices" class="nav-tab" @click="switchTab('assign-devices')" :class="isActiveTab('assign-devices')"> {{ $translate('assign-devices') }} </a>
                    </li>
                </ul>
            </div>
            <div class="tab-content">
                <div id="map" class="tab-content__item" :class="isActiveTab('map')">
                    <div class="addition">
                        <div class="map search-map" style="border-radius: var(--border-radius-7); overflow: hidden">
                            <img src="img/farm-map-map4.jpg" style="max-width: 100%; width: 100%" />
                            <input type="text" class="input bg-left search-icon" placeholder="Search" />
                        </div>
                    </div>
                </div>
                <div id="basic-info" class="tab-content__item" :class="isActiveTab('basic-info')">
                    <div class="addition">
                        <div class="wizard-item">
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('field-name') }}</label>
                                        <input type="text" class="input" placeholder="Field name 1" v-model="basicInfo.fieldName" />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('total-area') }}</label>
                                        <input type="text" class="input" placeholder="0.1 m²" v-model="basicInfo.totalArea" />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('grazeable-or-arable-area') }}</label>
                                        <input type="text" class="input" v-model="basicInfo.grazeableOrArableArea" />
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('land-use') }}</label>
                                        <Dropdown
                                            :options="[
                                                'Placeholder 1',
                                                'Placeholder 2',
                                                'Placeholder 3',

                                            ]"
                                            :current="basicInfo.landUse"
                                            name="landUse"
                                            @change="updateBasicInfo"
                                        />


                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('crop-type') }}</label>
                                        <Dropdown
                                            :options="[
                                                'Placeholder 1',
                                                'Placeholder 2',
                                                'Placeholder 3',

                                            ]"
                                            :current="basicInfo.cropType"
                                            name="cropType"
                                            @change="updateBasicInfo"
                                        />
                                        
                                    </div>
                                </div>
                                <div class="cards__col-6">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('colour-on-map') }}</label>
                                        <Dropdown
                                            :options="[
                                                'Placeholder 1',
                                                'Placeholder 2',
                                                'Placeholder 3',

                                            ]"
                                            :current="basicInfo.colourOnMap"
                                            name="colourOnMap"
                                            @change="updateBasicInfo"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="items-group cards">
                                <div class="cards__col-12">
                                    <div class="el-form">
                                        <label class="el-form__title">{{ $translate('description') }}*</label>
                                        <textarea class="textarea h-140" :placeholder="$translate('write-product-description-here...')" v-model="basicInfo.description"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="assign-devices" class="tab-content__item" :class="isActiveTab('assign-devices')">
                    <div class="addition">
                        <div class="flex content-flex-heading">
                            <div class="content__header none-border">
                                <div class="content__header-left">
                                    <div class="headline headline--sm">
                                        <h2 class="headline__title">{{ $translate('assign-devices') }}</h2>
                                        <p class="headline__text">{{ $translate('here-you-can-find-devices-assigned-to-your-field') }}</p>
                                    </div>
                                </div>
                                <div class="content__header-right">
                                    <div class="buttons-group margin-btn flex-auto">
                                        <button type="button" class="btn btn-w-shadow btn-standart-2">
                                            <inline-svg :src="require(`@/assets/img/add-iot-icon.svg`)"/>
                                            <span>{{ $translate('add-devices') }}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul class="editable-list">
                            <li class="editable-list__item">
                                <div class="data-row">
                                    <ul class="data-row__list">
                                        <li class="data-row__item">Thermometer Name 1</li>
                                        <li class="data-row__item">
                                            <span class="data-row__icon">
                                                <inline-svg :src="require(`@/assets/img/Temperature.svg`)"/>
                                            </span> Temperature
                                        </li>
                                        <li class="data-row__item">Italy, Rome</li>
                                    </ul>
                                    <div class="data-row__controls">
                                        <button type="button" class="btn btn-icon red-color">
                                            <inline-svg :src="require(`@/assets/img/ico-remove.svg`)"/>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="buttons-group margin-btn flex-auto">
                            <button type="button" class="btn btn-w-shadow btn-standart-2">
                                <inline-svg :src="require(`@/assets/img/add-iot-icon.svg`)"/>
                                <span>{{ $translate('add-devices') }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <FooterTabsControl
            cancelRoute="farm-map"
            :confirmName="$translate('add-field')"
            @confirm="confirm"
            @swipeTab="swipeTab"
            :pageTabs="pageTabs"
            :activeTab="activeTab"
        >
        <!-- before translate confirmName="Add Field" -->
        </FooterTabsControl>
    </div>
</template>

<script>
import Base from "@/components/base";
import { mixTabs } from '@/mixins';
export default {
    name: "AddField",
    components: {
        ...Base,
    },
    mixins: [mixTabs],
    data() {
        return {
            basicInfo:{
                fieldName:null,
                totalArea:null,
                grazeableOrArableArea:null,
                landUse:null,
                cropType:null,
                colourOnMap:null,
                description:null
            },
            areaType: 0,
            pageTabs:['map', 'basic-info', 'assign-devices']
        };
    },
    computed: {
        
    },
    methods: {
        confirm(){
            console.log("confirm")
        },
        updateBasicInfo(ev) {
            const target = ev.target;
            const value = target.value;
            const name = target.name;
            this.basicInfo[name] = value;
        },
        changeAreaType(index){
            this.areaType=index
        },

        pushRoute(page) {
            this.$router.push({ path: page });
        },
    },
};
</script>
